<script lang="js">
import Vue from "vue";
import { getOnlySetQueryParameters } from "@/application/util/queryString";
import Qs from "qs";
import { snakeToCamel } from "@/api/util/keyConverter";

export default Vue.extend({
  name: "FilterBar",
  props: {
    component: {
      required: true,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    internalFilters: undefined,
    isOpen: false,
    isVisible: false,
  }),
  computed: {
    hasFilters() {
      const queryParams = getOnlySetQueryParameters(this.getQueryParams());
      if (!queryParams) return;
      return !!Object.keys(queryParams).length;
    }
  },
  watch: {
    filters: {
      handler(val) {
        this.internalFilters = val;
      },
      deep: true,
      immediate: true,
    },
    internalFilters: {
      handler() {
        this.$emit("update:filters", this.internalFilters);
      },
      deep: true,
    },
    isOpen(value) {
      if(!value) return;
      setTimeout(() => this.isVisible = true, 10)
    }
  },
  methods: {
    getQueryParams() {
      return snakeToCamel(Qs.parse(this.$route.query, { allowSparse: false }));
    },
    setQueryParams() {
      this.$router.push({
        query: {
          'client_id': this.internalFilters?.clientId,
          'filter': this.internalFilters?.filter,
          'purchase_order_status_ids[]' : this.internalFilters?.purchaseOrderStatusIds,
          'assigned_user_ids[]' : this.internalFilters?.assignedUserIds,
          'is_preadviced' : this.internalFilters?.isPreadviced,
          'is_not_according_to_conditions' : this.internalFilters?.isNotAccordingToConditions,
          'planned_date_from' : this.internalFilters?.plannedDateFrom,
          'planned_date_to' : this.internalFilters?.plannedDateTo,
        },
      });
      this.$emit("update:filters", this.internalFilters);
    },
    handleClickOutside() {
      if (!this.isOpen ||!this.isVisible) return;
      this.closeNavigationDrawer();
    },
    closeNavigationDrawer() {
      this.isOpen = false;
      this.isVisible = false;
    }
  },
});
</script>

<template>
  <div>
    <v-badge :value="hasFilters" color="accent" dot class="align-self-center">
      <v-btn @click.stop="isOpen = !isOpen" icon>
        <v-icon>$filter</v-icon>
      </v-btn>
    </v-badge>

    <v-navigation-drawer
        v-model="isOpen"
        v-bind="$attrs"
        v-on="$listeners"
        v-click-outside="handleClickOutside"
        class="pa-3 filter-bar"
        width="400"
        fixed
        right
        app
        stateless
        temporary
    >
      <template #prepend>
        <v-btn class="mb-4" icon @click="closeNavigationDrawer">
          <v-icon>$close</v-icon>
        </v-btn>
      </template>

      <component
        v-if="component"
        :is="component"
        :filters.sync="internalFilters"
        @update:queryParams="setQueryParams"
      ></component>
    </v-navigation-drawer>
  </div>
</template>

<style scoped lang="scss">
.filter-bar {
  border-top-left-radius: 4px;
}
</style>
