export const getOnlySetQueryParameters = (params) =>
    Object.fromEntries(
        Object.entries(params || {}).filter(
            ([, value]) =>
                value !== null &&
                value !== "" &&
                value !== undefined &&
                !(Array.isArray(value) && value.length === 0)
        )
    );
