import { getPaginated } from '@/api/implementation/app/wrapper.js';
import { get, post } from '@/api/implementation/app';

export const index = (...args) => getPaginated(`report/purchase-orders`, ...args);

export const show = (purchaseOrderId) => get(`purchase-order/${purchaseOrderId}`);

export const update = (purchaseOrder) => post(`purchase-order/${purchaseOrder.id}`, purchaseOrder);

export const statusAutoComplete = (...args) => getPaginated(`purchase-order-status/autocomplete`, ...args);

export const exportClientDelivery = (queryParams = {}) => {
   return get(`report/purchase-orders/export`, {
        params: queryParams,
        responseType: "blob",
    });
}
